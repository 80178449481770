import React from 'react'
import { graphql } from "gatsby"
import ContentTerminosYCondiciones from "../components/organisms/content-terminos-y-condiciones/content-terminos-y-condiciones"
import Layout from "../shared/components/organisms/layout/layout"
import SEO from "../shared/components/atoms/seo/seo"


const TerminoyCondiciones = ({ data: { allContentfulExtranetSeccionTerminosYCondiciones } }) => {
    return (
        <Layout>
            <SEO
                title={allContentfulExtranetSeccionTerminosYCondiciones.nodes[0].metaTitulo}
                description={allContentfulExtranetSeccionTerminosYCondiciones.nodes[0].metaDescripcion.metaDescripcion}
            />
            <ContentTerminosYCondiciones />
        </Layout>
    )
}


export const query = graphql`
    query META_QUERY_TERMINOS {
        allContentfulExtranetSeccionTerminosYCondiciones{
            nodes{
            metaTitulo
            metaDescripcion{
                metaDescripcion
            }
            }
        }
    }
`

export default TerminoyCondiciones
