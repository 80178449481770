import React from 'react'
import { graphql, useStaticQuery, } from "gatsby"
import useMobile from "../../../shared/hooks/useMobile"
import BreadCrum from "../../../shared/components/atoms/breadcrumb/breadcrumb"
import RichText from "../../../shared/components/molecules/rich-text/rich-text"
import "./content-terminos-y-condiciones.scss"

const ContentTerminosYCondiciones = () => {

    const { allContentfulExtranetSeccionTerminosYCondiciones: { nodes: sectionInfo } } = useStaticQuery(graphql`
    query QUERY_TERMS_CONDITIONS {
        allContentfulExtranetSeccionTerminosYCondiciones{
            nodes{
                bannerPrincipal{
                    imagenesDelBanner{
                        title
                        file {
                                url
                                details {
                                    image {
                                        height
                                    }
                                }
                            }
                            fluid {
                                src
                                srcSet
                                base64
                                aspectRatio
                                sizes
                            }
                    }
                }       
                terminosYCondiciones {
                    raw
                }
            }
        }
        }    
    `)

    const isMobile = useMobile()

    return (
        <>
            <section className="f-section-terms-and-conditions">
                <img
                    src={sectionInfo[0].bannerPrincipal.imagenesDelBanner[isMobile ? 1 : 0].file.url}
                    alt={sectionInfo[0].bannerPrincipal.imagenesDelBanner[isMobile ? 1 : 0].title}
                    className="f-terms-and-conditions__banner"
                />
                <BreadCrum outOfBanner />
                <div className="f-terms-and-conditions">
                    <h1 className="f-terms-and-conditions__title">Términos y Condiciones</h1>
                    <div className="f-terms-and-conditions__body">
                        <RichText jsonInfo={JSON.parse(sectionInfo[0].terminosYCondiciones.raw)} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContentTerminosYCondiciones
